import React,{useState} from 'react';
import { Card, CardContent, Typography, Box, Rating, Avatar, Button, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams,useNavigate } from 'react-router-dom';


const DoctorProfileCard = ({ doctor, Id }) => {

  const doctorId = Id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleRequestConsultation = () => {
    navigate('/requestconsultation/'+ doctorId)
  };

  return (
  <>
  <Card sx={{padding: '3em auto !important', borderRadius:'10px',boxShadow:'3px 3px 15px silver', margin:'0 1em' }}>
 
  <Grid container direction="column">
  <Box sx={{display:'flex',justifyContent:'space-evenly', flexDirection: 'column', alignItems:'flex-start'}}>
    <Grid item>
      <Avatar
        sx={{
          width: 60,
          height: 60,
          margin: '20px 0 0 20px',
          bgcolor: 'var(--color-primary)',
          fontSize: '1.5rem',
        }}
      >
      {doctor.full_name.charAt(0)}
      </Avatar>
    </Grid>
    <Grid item>
      <CardContent >
        <Typography variant="h6" component="div" sx={{ textAlign: 'left' }}>
          {doctor.full_name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" sx={{ textAlign: 'left' }}>
          {doctor.speciality}
        </Typography>
      
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
          {doctor.experience} of experience
        </Typography>
        </CardContent>
        </Grid>
        </Box>
        <Grid item>
        <Box sx={{display: 'flex', flexDirection: 'column', marginBottom:'1rem',}}>
        <Link to={`/visitdoctorsprofile/${doctorId}`}>
        <Button
          sx={{ border: '1px solid var(--color-primary)', height: '3rem', width: '90%', fontSize: '14px', color: 'var(--color-primary)', margin: '5px 15px' }}
          onClick={handleOpenModal}
        >
          View Profile
        </Button>
        </Link>
        
        <Button
        onClick={handleRequestConsultation}
        sx={{ backgroundColor: 'var(--color-primary)', height: '3rem', width: '90%', fontSize: '14px', color: 'white', margin: '5px 16px',
        '&:hover': {
          backgroundColor: 'var(--color-primary)', // Keep the same color on hover
        }
      }}
      
      >
        Request Consultation
      </Button>
      
      </Box>
    </Grid>
  </Grid>
</Card>


  </>
  );
};

export default DoctorProfileCard;
