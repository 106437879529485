import React,{useState, useEffect} from 'react';
import '../styles/subscription.css';
import {Link,useNavigate } from 'react-router-dom';
import bgavatar from '../assets/providers.svg';
import wave from '../assets/wave.png';
import { FiUser, FiLock, FiMail, FiPhone, FiSend } from 'react-icons/fi';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EnterTransition from '../utility/EnterTransition.js'



const Subscription = () => {


    const MySwal = withReactContent(Swal);

    const [newConsultant, setNewConsultant] = useState({
       
        name:'',
        contact: '',
        email: '',
     
      });

      const [errors, setErrors] = useState({
        name: '',
        contact: '',
        email: '',
      });

      const [nameFocus, setNameFocus] = useState(false);
      const [contactFocus, setContactFocus] = useState(false);
      const [emailFocus, setEmailFocus] = useState(false);
    
      const handleNameFocus = () => {
        setNameFocus(true);
      };
    
      const handleNameBlur = (e) => {
        if (!e.target.value.trim()) {
          setNameFocus(false);
        }
      };
    
      const handleContactFocus = () => {
        setContactFocus(true);
      };
    
      const handleContactBlur = (e) => {
        if (!e.target.value.trim()) {
          setContactFocus(false);
        }
      };
    
      const handleEmailFocus = () => {
        setEmailFocus(true);
      };
    
      const handleEmailBlur = (e) => {
        if (!e.target.value.trim()) {
          setEmailFocus(false);
        }
      };
    
     

 const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation checks
    if (name === 'name') {
      setErrors((prevErrors) => ({ ...prevErrors, name: value.trim() === '' ? 'Name cannot be empty' : '' }));
    } else if (name === 'contact') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact: value.trim() === '' ? 'Contact cannot be empty' : (isValidPhoneNumber(value) ? '' : 'Invalid phone number'),
      }));
    } else if (name === 'email') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: value.trim() === '' ? 'Email cannot be empty' : (isValidEmail(value) ? '' : 'Invalid email address'),
      }));
    }

    setNewConsultant((prevConsultant) => ({
      ...prevConsultant,
      [name]: value,
    }));
  };


  
      const handleSubmit = async (e) => {
        e.preventDefault();
         // Validation checks before submitting the form
    if (newConsultant.name.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Name cannot be empty' }));
      return;
    }

    if (!isValidPhoneNumber(newConsultant.contact)) {
      setErrors((prevErrors) => ({ ...prevErrors, contact: 'Invalid phone number' }));
      return;
    }

    if (!isValidEmail(newConsultant.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
      return;
    }


    const data = {
      ...newConsultant,
      subject : "New Consultant Request",
      key : "consultantRequest",
      forAdminOnly : true,
    }

    try {
          const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/public/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

        } catch (error) {
          // Handle network or other errors
          console.error('Error occurred while making the API request:', error);
        }
        setNewConsultant({
          name:'',
          contact: '',
          email: '',
          
        })
        setErrors({
          name: '',
          contact: '',
          email: '',
        })
        MySwal.fire({
          icon: 'success',
          title: 'Thank You!',
          text: 'Your request has been sent successfully.',
          confirmButtonText: 'OK!',
          // customClass: {
          //   confirmButton: 'cutom-swal-confirm-button',
          //   cancelButton: 'custom-swal-cancel-button',
          // },
        })
      };

      // const isValidPhoneNumber = (value) => /^\d{10}$/.test(value);
      const isValidPhoneNumber = (value) => value && value.length >=9;
      const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    

  return (
    <EnterTransition>
    <div>
    <img className="wave" src={wave} alt="Wave" />
    <div className="pcontainer">
      <div className="img">
        <img src={bgavatar} alt="Background" className="img"/>
      </div>
      <div className="login-content">
        <form onSubmit={handleSubmit}>
          <h2 className="title">More INformation Request</h2>
          <div className={`input-div one ${nameFocus ? 'focus' : ''} ${errors.name ? 'error' : ''} ${errors.name ? 'error-border' : ''}`}>
          <div className="i">
            <FiUser />
          </div>
          <div className="div">
            <h5>Name</h5>
            <input
              type="text"
              name="name"
              className={`input ${errors.name ? 'error-border' : ''}`}
              value={newConsultant.name}
              onChange={handleChange}
              onFocus={handleNameFocus}
              onBlur={handleNameBlur}
            />
            {errors.name && <small className="error-text">{errors.name}</small>}
          </div>
        </div>
        <div className={`input-div one ${contactFocus ? 'focus' : ''} ${errors.contact ? 'error' : ''}`}>
        <div className="i">
          <FiPhone />
        </div>
        <div className="div">
          <h5>Contact</h5>
          <input
            type="text"
            name="contact"
            className={`input ${errors.contact ? 'error-border' : ''}`}
            onFocus={handleContactFocus}
            onBlur={handleContactBlur}
            value={newConsultant.contact}
            onChange={handleChange}
          />
          {errors.contact && <small className="error-text">{errors.contact}</small>}
        </div>
      </div>
      
      <div className={`input-div one ${emailFocus ? 'focus' : ''} ${errors.email ? 'error' : ''}`}>
        <div className="i">
          <FiMail />
        </div>
        <div className="div">
          <h5>Email</h5>
          <input
            type="text"
            name="email"
            className={`input ${errors.email ? 'error-border' : ''}`}
            onFocus={handleEmailFocus}
            onBlur={handleEmailBlur}
            value={newConsultant.email}
            onChange={handleChange}
          />
          {errors.email && <small className="error-text">{errors.email}</small>}
        </div>
      </div>
      
          <button type="submit" className="btn">
            <FiSend /> Submit
          </button>
        </form>
      </div>
    </div>
  </div>
    </EnterTransition>
  )
}

export default Subscription