import React, { useState } from 'react'
import { Button, Row, Col } from 'antd';
import laptop from '../../assets/laptop.png'
import brainGif from '../../assets/brain.gif'
import arrowGif from '../../assets/arrow.gif'
import innerArrowGif from '../../assets/innerArrow.gif'
import starGif from '../../assets/star.gif'
import suitCaseGif from '../../assets/suitCase.gif'
import heroFrameArrow from '../../assets/hero-frame-arrow.png'
import './UpdateBanner.css'
import DemoModal from './overview/DemoModal';

const UpdateBanner = () => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <div className="hero-section">
                <Row gutter={[2, 30]} align="middle">
                    <Col xs={24} md={12}>
                        <div className="hero-content">
                            <h1 style={{ fontWeight: 700, color: 'var(--bg-primary)' }}>Your All-in-One Solution <br /> for Streamlined Practice Management</h1>
                            <Button onClick={() => setOpenModal(true)} style={{ marginTop: "10px", backgroundColor: "#706FE5", borderRadius: "12px", padding: "10px 40px 10px 40px" }} type="primary" size="large">Get a Demo</Button>
                            <div className="hero-content-arrow"> <img src={heroFrameArrow} alt="Hero" /></div>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="hero-image">
                            <div className='hero-gif'>
                                <div><img width='24px' height='24px' src={brainGif} /></div>
                                <div className='hero-report-gif'><img width='24px' height='24px' src={arrowGif} /><span>Reports</span></div>
                                <div><img width='24px' height='24px' src={innerArrowGif} /></div>
                                <div><img width='24px' height='24px' src={starGif} /></div>
                                <div><img width='24px' height='24px' src={suitCaseGif} /></div>
                            </div>
                            <img width="550px" height="370px" src={laptop} alt="Hero" />
                        </div>
                    </Col>
                </Row>
            </div>
            {openModal && <DemoModal openModal={openModal} onCancel={() => setOpenModal(false)} />}
        </>
    )
}

export default UpdateBanner