import React from 'react'
import { Button } from 'antd';
import businessBtn from '../../../assets/businessBtn.png'
import '../BusinessSection.css'
import { useNavigate } from 'react-router-dom';

const BusinessOverView = ({ data }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="hero-image">
                <img width="550px" height="370px" src={data?.image} alt="Image" />
                <div style={{ marginTop: "20px" }}>
                    <h2 style={{ fontWeight: 700, color: "#1E1F4B", paddingRight: "65px", fontFamily: "Poppins" }}>{data?.title}</h2>
                    <p style={{ color: "#2E3E5C", fontSize: "16px" }}>{data?.content}
                    </p>
                    <p style={{ color: "#2E3E5C", fontWeight: 700, fontSize: "16px" }}>Grow your business with Bluu!</p>
                </div>
                <div style={{ marginTop: "30px", marginBottom: "15px" }}>
                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <img src={businessBtn} />
                        <span style={{ fontSize: "16px", color: "#1E1F4B", fontFamily: "Poppins", padding: "0px 18px" }}>Super useful and easy to use interface</span>
                    </div>
                    <div style={{ display: "flex" }}>
                        <img src={businessBtn} />
                        <div style={{ fontSize: "16px", color: "#1E1F4B", fontFamily: "Poppins", padding: "0px 18px" }}>Easiest way to grow your business in days</div>
                    </div>
                </div>
                <Button onClick={()=> navigate('/#learn-more')} style={{ backgroundColor: '#706FE5', color: '#fff', padding: '18px 24px 18px 24px', width: "100%" }}>Learn More</Button>
            </div>
        </>
    )
}

export default BusinessOverView