import React from 'react'
import styles from './Banner.module.css'

const Banner = (props) => {
    const { className, background, title } = props;

    return (
        <div className={`${className} ${styles.banner}`}  style={{ backgroundImage: `url("${background}")` }}>
            <h2>{title}</h2>
        </div>
    )
}

export default Banner
