import React, { useState } from 'react';
import { Button, Modal, Form, Input, Row, Col, message } from 'antd';
import demoModalPic from '../../../assets/demoModal.png'
import './DemoModal.css';

const DemoModal = ({ openModal, onCancel }) => {

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        practiceName: '',
    })
    const [loading, setLoading] = useState(false)
    const [validation, setValidation] = useState(false)
    const { firstName, lastName, phone, email, practiceName, } = state
    const onChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const onSubmit = async () => {
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if (!firstName || !lastName || !phone || !email || !practiceName) {
            setValidation(true)
            message.error("All fields are required")
        } else if (!emailRegex.test(email)) {
            message.error("Invalid email")
        } else {
            setLoading(true)
            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/public/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...state,
                        key: 'providerDemoRequestNew',
                        subject: 'Bluu Health Provider Demo',
                    }),
                });

            } catch (e) {
                console.log("error", e)
            }
            setLoading(false)
            message.success("Someone from our team will be in touch shortly.")
            //After api call reset state
            setValidation(false)
            setState({
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                practiceName: '',
            })
            onCancel()
        }
    }

    return (
        <>
            <Modal
                visible={openModal} // Use visible instead of open to control visibility
                onOk={onCancel}
                onCancel={onCancel}
                footer={null}
                wrapClassName="fullscreen-modal"
                width="80%" // Adjust modal width as needed
                bodyStyle={{ display: 'flex', padding: 0 }} // Remove padding inside the modal body
            >
                <div className='modal-image' >
                    <img
                        src={demoModalPic}
                        alt="placeholder"
                        style={{ width: '100%', height: '100%', borderRadius: "10px" }}
                    />
                </div>
                <div style={{ flex: 1, padding: '20px' }}>
                    <h2 style={{ color: "#252B42", fontFamily: "Poppins" }}>Reach out to us</h2>
                    <p style={{ color: "#2E3E5C" }}>With integrated tools across desktop and mobile, you can run smooth and interactive telehealth sessions, customize treatment plans, and securely communicate with clients via the Client Portal. You can also add unlimited clients for free</p>
                    <Form className='demo-form' layout="horizontal">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    <Input value={firstName} status={validation && !firstName && 'error'} onChange={({ target }) => onChange('firstName', target.value)} placeholder="First Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <Input value={lastName} status={validation && !lastName && 'error'} onChange={({ target }) => onChange('lastName', target.value)} placeholder="Last Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    <Input value={phone} status={validation && !phone && 'error'} onChange={({ target }) => onChange('phone', target.value)} placeholder="Phone" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <Input value={email} status={validation && !email && 'error'} onChange={({ target }) => onChange('email', target.value)} placeholder="Email" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item >
                            <Input value={practiceName} status={validation && !practiceName && 'error'} onChange={({ target }) => onChange('practiceName', target.value)} placeholder="Practice Name" />
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={loading} onClick={onSubmit} style={{
                                backgroundColor: '#706FE5', color: "#fff", width: "100%", padding: '18px 24px 18px 24px'
                            }}>Get a Demo</Button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        </>
    );
};

export default DemoModal;
