import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Box
  } from '@mui/material';
  
  const ReviewInfo = ({ formik }) => {
    const { values } = formik;
    return (
      <>
       <Box sx={{maxHeight:'250px', overflowY:'scroll'}}>
        <Typography variant="overline">
          Personal Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Name"
              secondary={values.fullName}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Email Address"
              secondary={values.email}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Contact"
              secondary={values.phone}
            />
          </ListItem>
          
        </List>
        <Typography variant="overline" >
          Appointment Details
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Appointment Date"
              secondary={values.appointmentDate}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Time"
              secondary={values.time}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Meeting Type"
              secondary={values.meetingType}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Payment Type"
              secondary={values.paymentMethod}
            />
          </ListItem>
        </List>
        </Box>
      </>
    )
  }
  
  export default ReviewInfo