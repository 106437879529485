import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  TextField,
  IconButton,
  Modal,
  Paper,
  Typography,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import {MdTune} from 'react-icons/md'
//import DoctorsData from '../content/DoctorsData.json'
import DoctorsProfileCard from '../components/profiles/DoctorsProfileCard.js';
import SearchBar from '../components/search/SearchBar.js';
import EnterTransition from '../utility/EnterTransition.js'
import Banner from '../components/Banner/Banner.js';
import bg from '../assets/inner-bg.webp'
import { useParams } from 'react-router-dom';

const DoctorsProfiles = () => {
  const [DoctorsData, setDoctorsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [timingFilter, setTimingFilter] = useState('');
  const [consultancyFeeMin, setConsultancyFeeMin] = useState('');
  const [consultancyFeeMax, setConsultancyFeeMax] = useState('');
  const [sortByAvailability, setSortByAvailability] = useState(false);
  const [sortByExperience, setSortByExperience] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [filterResults, setFilterResults] = useState([]);

  const { appointment, zipcode } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construct the URL based on available parameters
        let url = process.env.REACT_APP_BASE_URL +'/api/providers';

        // Fetch data
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const resdata = await response.json();
        const responseData= resdata.data
         // Filter data based on appointment and zipcode
         const filteredData = responseData.filter((item) => {
          return (
            (!appointment || item.attributes.appointment === appointment) ||
            (!zipcode || item.attributes.zipcode === zipcode)
          );
        });

       setDoctorsData(filteredData);
      // console.log(filteredData)
      } catch (error) {
        console.error('Error fetching data:', error.message);
        // Handle error, show error message, etc.
      }
    };

    fetchData();
  }, []);


  const handleFilterModalOpen = () => {
    setFilterModalOpen(true);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const handleApplyFilters = () => {
    setFilterModalOpen(false);
    // Apply filters and update filterResults state
     const newFilterResults = [];
    DoctorsData && DoctorsData.forEach((doctor) => {
      if (doctor.attributes &&
          doctor.attributes.charges >= consultancyFeeMin &&
          doctor.attributes.charges <= consultancyFeeMax) {
        newFilterResults.push(doctor);
        //console.log(doctor)
      }
    });
   
    setFilterResults(newFilterResults);
    if (sortByExperience) {
      newFilterResults.sort((a, b) => b.attributes.experience - a.attributes.experience);
    }
   
  };

  // Sorting logic for filter results
  // if (sortByAvailability) {
  //   filterResults.sort((a, b) => a.availability - b.availability);
  // }
 

  const handleSearch = (searchQuery) => {
    // Clear filter results
    setFilterResults([]);
    //
    // Filter and update searchResults state
    const newSearchResults = DoctorsData && DoctorsData.filter((doctor) => doctor.attributes &&
     doctor.attributes.speciality.toLowerCase().includes(searchQuery.toLowerCase())
    );
    //console.log(newSearchResults)
    setSearchResults(newSearchResults);
    
  
  };

  return (
    <>
    <EnterTransition>
    <Banner
    background={bg}
    title={"Our Therapists"}
    />
    <Container sx={{marginTop: '4%', marginBottom:'5%'}}>
   
      <Grid container spacing={3} alignItems="center" >
        <Grid item xs={12} sm={6} >
          <TextField
            label="Search Doctors"
            fullWidth
            variant="outlined"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{
              backgroundColor:'white',
              '@media (min-width: 1280px)': {
                margin: '2%'},
              }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => handleSearch(searchQuery)}>
                  <Search />
                </IconButton>
              ),
            }}
           
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <IconButton onClick={handleFilterModalOpen}  sx={{margin:'2%'}}>
            <MdTune style={{color:'var(--bg-primary)',fontSize:'2.5rem'}}/>
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{margin:'2px 0'}}>
      {(searchResults.length === 0 && filterResults.length === 0
        ? DoctorsData // Display all cards when no filters are applied
        : searchResults.length > 0
        ? searchResults // Display search results when they exist
        : filterResults // Display filter results when search results are empty
      ).map((doctor) => (
        <Grid item xs={12} sm={6} md={4} key={doctor.id}  sx={{marginBottom:'2rem'}}>
          <DoctorsProfileCard doctor={doctor.attributes} Id={doctor.id} />
        </Grid>
      ))}
      </Grid>

      <Modal open={isFilterModalOpen} onClose={handleFilterModalClose}>
      <Paper
        sx={{
          position: 'absolute',
          //width: 400,
          backgroundColor: 'white',
          border: '2px solid var(--color-secondary)',
          boxShadow: 24,
          p: 4,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '8px',
          color:'var(--color-text-primary)'
        }}
      >
        <Container>
          <Typography variant="h5" gutterBottom>
            Filter Doctors
          </Typography>
          <form>
            <Grid container spacing={2}>
              {/* Timing Filter */}
              {/* <Grid item xs={12}>
                <TextField
                  label="Timings"
                  variant="outlined"
                  fullWidth
                  value={timingFilter}
                  type='time'
                  onChange={(e) => setTimingFilter(e.target.value)}
                  InputLabelProps={{shrink:true}}
                />
              </Grid> */}
    
              {/* Consultancy Fee Range */}
              <Grid item xs={6}>
                <TextField
                  label="Min Consultancy Fee"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={consultancyFeeMin}
                   onChange={(e) => setConsultancyFeeMin(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Max Consultancy Fee"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={consultancyFeeMax}
                   onChange={(e) => setConsultancyFeeMax(e.target.value)}
                />
              </Grid>
    
              {/* Sorting Checkboxes */}
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortByAvailability}
                       onChange={(e) => setSortByAvailability(e.target.checked)}
                       sx={{
                        '&.Mui-checked': {
                          color: 'var(--bg-primary)',
                        },
                      }}
                    />
                  }
                  label="Sort by Availability"
                />
              </Grid> */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortByExperience}
                      onChange={(e) => setSortByExperience(e.target.checked)}
                      sx={{
                        '&.Mui-checked': {
                          color: 'var(--bg-primary)',
                        },
                      }}
                    />
                  }
                  label="Sort by Experience"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{bgcolor:'var(--color-primary)',height:'3rem', width:'6rem',fontSize:'15px', color:'white',margin:'5px 10px', '&:hover': {
              backgroundColor: 'var(--color-primary)',
            }}}
            onClick={handleApplyFilters}
            >Apply</Button>
          </Grid>
          </form>
        </Container>
      </Paper>
    </Modal>
    
    </Container>
    </EnterTransition>
    </>
  );
};

export default DoctorsProfiles;
