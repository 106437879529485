import React from 'react';
import { Container, Grid, Card, CardHeader, CardContent, Typography, Box, Paper } from '@mui/material';
import serviceIllustration from '../assets/MentalHealth.png';
import Banner from '../components/Banner/Banner.js';
import bg from '../assets/inner-bg.webp'
import EnterTransition from '../utility/EnterTransition.js'
import individual from '../assets/psychotherapy-64.png'
import couple from '../assets/couple-64.png'
import family from '../assets/family-64.png'
import adolescence from '../assets/medical-64.png'


const services = [
    {
      title: 'Anxiety',
      description: 'Our expert therapists provide tailored anxiety management programs to help you regain control and reduce anxiety symptoms. We offer evidence-based strategies and support to address the root causes of your anxiety and help you lead a more fulfilling life.',
    },
    {
      title: 'Panic',
      description: 'Our panic disorder treatment focuses on helping you understand and manage panic attacks. We provide you with coping techniques, relaxation strategies, and counseling to reduce the frequency and intensity of panic episodes.',
    },
    {
      title: 'Struggles with Anger',
      description: 'Our anger management programs are designed to help you gain better control over your emotions. We work with you to identify triggers, improve communication, and develop healthier ways to express and manage your anger.',
    },
    {
      title: 'Postpartum',
      description: 'We offer postpartum support for new mothers experiencing emotional challenges after childbirth. Our experts provide therapy and guidance to address postpartum depression, anxiety, and adjustment difficulties, ensuring a smooth transition to motherhood.',
    },
    {
      title: 'Bipolar Disorder',
      description: 'Our bipolar disorder treatment involves mood stabilization, medication management, and therapy. We help individuals with bipolar disorder regain stability, manage mood swings, and lead a more balanced life.',
    },
    {
      title: 'Personality Disorder',
      description: 'Our comprehensive personality disorder treatment focuses on developing self-awareness and improving interpersonal relationships. We provide therapy and counseling to address the challenges associated with personality disorders.',
    },
    {
      title: 'Substance Use',
      description: 'Our substance use recovery programs offer a combination of counseling, support, and rehabilitation to help individuals overcome addiction. We tailor our approach to your specific needs and work towards your recovery goals.',
    },
    {
      title: 'Partner Intimacy',
      description: 'We provide couples counseling and intimacy therapy to help partners enhance their emotional connection and physical intimacy. Our experts offer guidance and strategies to strengthen your relationship.',
    },
    {
      title: 'Chronic Insomnia',
      description: 'Our chronic insomnia treatment aims to improve sleep patterns and overall sleep quality. We assess the root causes of insomnia and provide strategies to help you get a good night\'s sleep on a regular basis.',
    },
    {
      title: 'Addiction',
      description: 'Our addiction recovery programs are designed to support individuals in their journey to sobriety. We offer a range of services, including counseling, group therapy, and relapse prevention strategies.',
    },
    {
      title: 'Depression',
      description: 'Our depression support includes therapy and counseling to improve your mental well-being and find hope. Our experts work with you to identify the underlying causes of depression and develop strategies for managing it effectively.',
    },
    {
      title: 'Grief',
      description: 'Our grief counseling and support services help individuals cope with the loss of a loved one. We provide a safe space to express emotions, work through the grieving process, and find healing and acceptance.',
    },
    {
      title: 'Chronic Pain',
      description: 'Our chronic pain management programs focus on improving your quality of life while dealing with persistent pain. We offer a holistic approach that includes pain relief strategies, coping mechanisms, and emotional support.',
    },
    {
      title: 'Phobias',
      description: 'Our phobia treatment helps individuals confront and overcome their fears. We use evidence-based techniques, exposure therapy, and counseling to reduce the impact of phobias on your daily life.',
    },
    {
      title: 'ADHD',
      description: 'Our ADHD (Attention Deficit Hyperactivity Disorder) support involves comprehensive assessments and tailored treatment plans. We provide strategies and interventions to help individuals manage symptoms and improve focus and organization.',
    },
    {
      title: 'Eating Disorder',
      description: 'Our eating disorder treatment focuses on addressing the physical and psychological aspects of disordered eating. We offer therapy, nutritional counseling, and support to individuals seeking recovery.',
    },
    {
      title: 'Self Harm',
      description: 'We provide therapy and support for individuals struggling with self-harm behaviors. Our aim is to help you find healthier ways to cope with emotional distress and build a path towards healing and self-compassion.',
    },
    {
      title: 'Trauma & PTSD',
      description: 'Our trauma and PTSD (Post-Traumatic Stress Disorder) therapy is tailored to help individuals recover from past traumas. We offer evidence-based treatments to reduce symptoms and promote emotional healing and resilience.',
    },
  ];
  

const ServicesPage = () => {
  return (
    <EnterTransition>
    <Banner
    background={bg}
    title={"Our Services"}
    />
    <Container maxWidth="lg" sx={{ marginTop: '5%', marginBottom:'10%', color:'var(--color-text-primary)' }}>
     
     <Box sx={{margin:'5%'}}>
     <h1>FIND A SPECIALIST</h1>
     <p style={{color:'black'}}>Begin by filling out a brief questionnaire sharing your prefrences and needs</p>
     </Box>

     <Box sx={{margin:'5%'}}>
     <h1>CONFIRM INFORMATION</h1>
     <p style={{color:'black'}}>After receiving your details, our team will confirm information, check insurance details and estimate cost.</p>
     </Box>

     <Box sx={{margin:'5%'}}>
     <h1>START YOUR CARE</h1>
     <p style={{color:'black'}}>Upon finding the perfect match, we will connect you with your Octave provider, initiating your care!</p>
     </Box>
   <hr />
   <Box sx={{ margin: '5%', textAlign: 'center' }}>
   <h1>TYPES</h1>
     <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', margin: '2% auto 2%' }}>
       {['Individual', 'Couple', 'Family', 'Adolescence'].map((type, index) => (
         <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
         <Paper key={index} sx={{background:'var(--color-primary)', color:'white', borderRadius: '50%', margin: '0 auto',
          padding: '20px', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center',
          '@media (min-width: 600px)': {
            margin: '0 auto',
          },
        }}>
             {type}
           </Paper>
         </Grid>
       ))}
     </Grid>
   </Box>

      <h2 style={{ marginBottom:'5%',marginTop:'5%', textAlign:'center'}}>
      SOLUTIONS WE PROVIDE
    </h2>
      <Grid container spacing={3}>
          
     
      {services.map((service, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
          <Card style={{background:'white', height: '100%', display: 'flex', flexDirection: 'column', boxShadow:'2px 3px 10px silver' }}>
            <CardHeader title={service.title} sx={{color:'var(--color-primary)'}} />
            <CardContent style={{ flex: 1 }}>
              <Typography variant="body1" >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </Container>
    </EnterTransition>
  );
};

export default ServicesPage;
