import React from 'react'
import PricingCard from '../components/pricing/PricingCard';
import LandingForm from '../components/landingform/LandingForm';
import UpdateBanner from '../components/Banner/UpdateBanner';
import BusinessSection from '../components/business/BusinessSection';
import OurProducts from '../dropdown/ourProducts/OurProducts';

const Home = () => {
  return (
    <>
      <UpdateBanner />
      <BusinessSection />
      <LandingForm />
      <PricingCard />
      {/* <OurProducts /> */}
    </>
  )
}

export default Home



