import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthMiddleware = ({ allowedRoles, children }) => {
  const user = useSelector(store => store.auth.user);

  useEffect(() => {
    if (user && allowedRoles && !allowedRoles.includes(user.role)) {
      // Redirect to a permission fail route if the user's role is not allowed
      return <Navigate to="/permission-fail" />;
    }
  }, [user, allowedRoles]);

  // If user is authenticated and their role is allowed, render the children
  if (user && user.token) {
    return children;
  }

  // If user is not authenticated, redirect to the login page
  return <Navigate to="/auth/login" />;
};

export default AuthMiddleware;
