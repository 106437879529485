import React from 'react';
import trust from '../assets/Trust.png';
import locate from '../assets/locate.png';
import care from '../assets/trust-100.png';
import growth from '../assets/growth-100.png';
import develop from '../assets/development-100.png';
// import { Container, Typography, Box, Grid } from '@mui/material';
import EnterTransition from '../utility/EnterTransition.js';
import { Row, Col, Container } from 'react-bootstrap';
import Banner from '../components/Banner/Banner.js';
import bg from '../assets/inner-bg.webp'
import mission_img from '../assets/about.jpg'

const AboutUs = () => {
  return (
    <EnterTransition>
      <Banner
      background={bg}
      title={"About Us"}
      />

      <section className='about_us'>
        <Container>
          <Row>
            <Col>
              <div className='heading'>
                  <h2>How We Work</h2>
                  <p>We are dedicated to fostering a healthier and more connected community, prioritizing not only physical health but also mental well-being. Through cutting-edge technology and a commitment to personalized care, we strive to empower our users to take charge of their health journey. Bluu Health is more than just a booking platform; it's a transformative approach to healthcare, ensuring that everyone can easily access the right professionals, resources, and support they need to lead fulfilling and healthy lives.</p>
                  </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className='about_us__box'>
                <img src={care} />
                <h4>Care</h4>
                <p>Your well-being, our priority. Moments of personalized care on your path to a healthier you</p>
                </div>
            </Col>

            <Col md={4}>
              <div className='about_us__box'>
                <img src={growth} />
                <h4>Growth</h4>
                <p>More than healing, it's about thriving. Partners with you on your journey to well-being, supporting growth in every aspect of your life</p>
              </div>
            </Col>

            <Col md={4}>
              <div className='about_us__box'>
                <img src={develop} />
                <h4>Trust</h4>
                <p>Confidence in every click. Committed to safeguarding your health and privacy with unwavering trust and transparency</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className='about_us__mission'>
        <Container>
          <Row>
            <Col md={6}>
              <div className='content_img'>
                <img src={mission_img} />
              </div>
            </Col>

            <Col md={6}>
              <div className='content'>
                <h2>Our Mission</h2>
              <p>At Bluu Health, our mission transcends conventional healthcare models, aiming not only to facilitate appointments but to redefine the entire healthcare experience. We believe in cultivating a sense of community and connection, where individuals feel heard and supported on their unique health and wellness paths. By seamlessly blending user-friendly technology with a deep commitment to individualized care, we strive to empower our users, encouraging them to actively participate in and navigate their health journeys. Beyond being a mere booking platform, Bluu Health represents a paradigm shift, embracing a holistic and transformative approach to healthcare. We are dedicated to ensuring that everyone, regardless of their circumstances, can effortlessly access the right professionals, resources, and support essential for leading fulfilling and healthy lives. Come join us in reshaping the future of healthcare—a future where personalization, convenience, and empowerment are at the core of the healthcare experience for all.</p>
                </div>
            </Col>
          </Row>
        </Container>
      </section>


    </EnterTransition>
  );
};

export default AboutUs;
