import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from'react-router-dom';
import '../styles/subscription.css' 
import bgavatar from '../assets/career.svg';
import wave from '../assets/wave.png';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EnterTransition from '../utility/EnterTransition.js'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  FormHelperText,
  Button
} from '@mui/material';
import PersonalInfo from './PersonalInfoForm';
import AppointmentDetails from './AppointmentDetailsForm';
import ReviewInfo from './ReviewInfo';
import MeetingDetails from './MeetingDetails'
import Loader from '../components/loader/Loader.js'


const steps = ['Personal Info', 'Appointment Details', 'Review and Submit'];

const BookAppointment = () => {

  const [loader,setLoader] = useState(false);
  const navigate = useNavigate();
  const { doctorId } = useParams();

 // const [providerId,setProviderId] = useState(0);

  const [activeStep, setActiveStep] = useState(0);
  const MySwal = withReactContent(Swal);

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

     //Format Time
     function convertTimeFormat(timeString) {
      // Assuming the input format is "HH:mm"
      const inputFormat = 'HH:mm';
      const outputFormat = 'HH:mm:ss.SSS';
    
      const inputDate = new Date(`1970-01-01T${timeString}:00Z`);
      const outputTime = inputDate.toISOString().slice(11, -1);
    
      return outputTime;
    }

  const formik = useFormik({
    initialValues: {
     
      fullName: '',
      email: '',
      phone: '',
      appointmentDate:'',
      time:'',
      paymentMethod: '',
      meetingType:'',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email'),
      fullName: Yup.string()
        .required('Name is required'),
      phone: Yup.string()
        .required('Contact is required'),
      appointmentDate: Yup.string()
        .required('date is required'),
      time: Yup.string()
        .required('Time is required'),
      paymentMethod: Yup.string()
        .required('Payment Method is required'),
      meetingType: Yup.string()
        .required('Meeting Type is required'),
      
    }),
    onSubmit:async (values, { setSubmitting }) => {
      if (activeStep === steps.length - 1) {
       
        if(!values){
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please fill all the fields',
          });
          return;
        }
        try{
          setLoader(true)
          const headers = {
            'Content-Type': 'application/json',
           // Authorization: `Bearer ${token}`,
          };
        
          
          const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/book-appointments', {
          method: 'POST',
          headers,
            body: JSON.stringify({
            
            data:{ 
             client_name:values.fullName,
             client_email:values.email,
             client_contact:values.phone,
             appointment_date:values.appointmentDate,
             start_time:convertTimeFormat(values.time),
             meeting_type:values.meetingType,
             payment_type:values.paymentMethod,
             provider_id: doctorId
            }
            
            }),
          });
          
          const result = await response.json();
       
          if (!response.ok) {
            setLoader(false);
         
          }

          if(response.ok){
            setLoader(false);
            MySwal.fire({
              //icon: 'success',
              title: 'Request Sent Successfully',
              text:'Your request for consultation has been sent successfully, you will receive a confirmation email shortly!',
              confirmButtonText: 'OK!',
              customClass: {
                icon: "no-before-icon",
            },
              
            }).then((result) => {
              if (result.isConfirmed) {
                // Redirect to the doctorsprofile page
                navigate('/doctorsprofile');
              }});
          
             
             
            
          }
       
        }catch(e){
          setLoader(false);
          MySwal.fire({
            //icon: 'success',
            title: 'Error',
            text:'There is an error proceeding your request.',
            confirmButtonText: 'OK!',
            customClass: {
              icon: "no-before-icon",
          },
            
          });
        }
        
      } else {
       
        setActiveStep((prevStep) => prevStep + 1);
      }
   
    }
  });

  const formContent = (step) => {
    switch(step) {
      case 0:
        return <PersonalInfo formik={formik} />;
      case 1:
        return <AppointmentDetails formik={formik} />;
      // case 2:
      //   return <MeetingDetails formik={formik} />;
      case 2:
        return  <ReviewInfo formik={formik} />;
      default:
        return <div>404: Not Found</div>
    }
  };

  return (
    <EnterTransition>
      {loader && (
          <Loader/>
          )}
    <div>

      <img className="wave" src={wave} alt="Wave" />
      <div className="pcontainer">
        <div className="img">
          <img src={bgavatar} alt="Background" className="img"/>
        </div>
      
        <div className="login-content">
       
    <Box
      sx={{
        maxWidth:'550px',
        padding:'2rem',
       display:'flex',alignItems:'center',justifyContent:'center',
       flexDirection:'column'

      }}
    >
    <h2 className="title">Book Appointment</h2>
      <Stepper
        activeStep={activeStep}
        orientation="horizontal"
      >
        {steps.map((label, index) => (
          <Step key={index}
          sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'var(--bg-primary)', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'var(--bg-primary)', // circle color (ACTIVE)
            },
          }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ padding: '20px' }}
        >
          {formContent(activeStep)}
        </Grid>
        {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Box sx={{display:'flex',justifyContent:'space-between',margin:'10px 20px'}}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button onClick={formik.handleSubmit}>
              Submit
            </Button>
          ) : (
            <Button onClick={() => {setActiveStep((prevStep) => prevStep + 1)}}>
              Next
            </Button>
          ) }
          </Box>
        </Grid>
      </Grid>
    </Box>
    </div>
    </div>
  </div>
  </EnterTransition>
  )
}

export default BookAppointment;