import React, { useEffect, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import { MdArrowBackIos} from 'react-icons/md';
import {
  Container,
  Box,
  Paper,
  Avatar,
  Typography,
  Grid,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import DoctorsData from '../content/DoctorsData.json'
import EnterTransition from '../utility/EnterTransition.js'


const VisitDoctorsProfile = () => {
    const { doctorId } = useParams();
    const [doctor, setDoctor] = useState({});
    const [doctorSlots, setDoctorSlots] = useState([]);
    const [services,setServices] = useState([])
     

    useEffect(() => {
      //Fetch Doctor's Data
      const fetchDoctorData = async () => {
        try {
          // Construct the URL based on the API endpoint and doctorId
          const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/providers/${doctorId}?populate=*`;
          
          // Make a GET request to the API
          const response = await fetch(apiUrl);
         // console.log(response)
  
          // Check if the request was successful (status code 200)
          if (response.ok) {
            // Parse the JSON response
            const resdata = await response.json();
            
            // Check if the data is not empty
            if (resdata) {
              // Set the doctor state with the first element of the data array
             //  console.log(resdata.data.attributes)
              setDoctor(resdata.data.attributes)
              //console.log(resdata.data.attributes)
              
            } else {
              console.error(`Doctor with ID ${doctorId} not found`);
            }
          } else {
            console.error(`Error fetching doctor data. Status: ${response.status}`);
          }
        } catch (error) {
          console.error('An error occurred while fetching doctor data:', error);
        }
      };
      //Fetch Doctor's Available Slots
      const fetchDoctorSlots = async () => {
        try {
          // Construct the URL based on the API endpoint and doctorId
          const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/doctors-available-slots?filters[provider_id][$eq]=${doctorId}`;
  
          // Make a GET request to the API
          const response = await fetch(apiUrl);
  
          // Check if the request was successful (status code 200)
          if (response.ok) {
            // Parse the JSON response
            const data = await response.json();
            if(data){
         
          // Set the doctorSlots state with the grouped data
          setDoctorSlots(data.data);
          //console.log(data.data)
     

            }
            
           
          } else {
            console.error(`Error fetching doctor slots data. Status: ${response.status}`);
          }
        } catch (error) {
          console.error('An error occurred while fetching doctor slots data:', error);
        }
      };
      //Fetch Available Services 
      const fetchAvailableServices = async () => {
        try {
          // Construct the URL based on the API endpoint and doctorId
          const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/type-of-services?filters[provider_id][$eq]=${doctorId}`;
          
          // Make a GET request to the API
          const response = await fetch(apiUrl);
      
          // Check if the request was successful (status code 200)
          if (response.ok) {
            // Parse the JSON response
            const servicesData = await response.json();
            
            // Check if the data is not empty
            if (servicesData) {
             // console.log(servicesData)
             
              setServices(servicesData.data);
            } else {
              console.error(`No available services found for Doctor with ID ${doctorId}`);
            }
          } else {
            console.error(`Error fetching available services. Status: ${response.status}`);
          }
        } catch (error) {
          console.error('An error occurred while fetching available services:', error);
        }
      };
      
     
  
      // Call the fetchDoctorData function
      fetchDoctorData();

       // Call the fetchDoctorSlots function
       fetchDoctorSlots();

      //Available Services function
      fetchAvailableServices();

    }, [doctorId]);


    ///Function to change time format
const formatTime = (timeString) => {
  if (!timeString) return ''; // Return empty string if timeString is not available

  // Parse the time string and create a Date object
  const parsedTime = new Date(`2000-01-01T${timeString}`);

  // Format the time as 12-hour format with AM/PM using toLocaleTimeString()
  return parsedTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
};


    // Function to convert 24-hour time to 12-hour time with AM/PM
const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':');
  const parsedHours = parseInt(hours, 10);

  const meridiem = parsedHours >= 12 ? 'PM' : 'AM';
  const formattedHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

  return `${formattedHours}:${minutes} ${meridiem}`;
};



  return (
    <EnterTransition>
    <Container maxWidth="md" sx={{ borderRadius:'10px', boxShadow:'1px 1px 10px silver',
    padding:'2rem', margin:'2rem auto'}}>
    {doctor ? (
        <Container sx={{height:'auto',paddingBottom:'auto'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link to='/doctorsprofile' style={{color:'var(--color-text)'}}> 
        <Button sx={{ color: 'var(--color-text)', fontSize: '1rem' }}>
            <MdArrowBackIos sx={{ color: 'var(--bg-primary)', fontSize: '2rem' }} /> Back
          </Button>
        </Link> 
        </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection:'column',
          margin:'1.5rem auto',
          textAlign:'left'
          
        }}
      >
        
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign:'center'
            }}
          >
            <Typography variant="h4" gutterBottom sx={{color:'var(--color-text-primary)'}}>
              Doctor's Profile
            </Typography>
        
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
               {(doctor.image && doctor.image.data )?
                <img
                  alt={`${doctor.full_name}`}
                 src={`${process.env.REACT_APP_BASE_URL}${doctor.image.data.attributes.url}`}
            style={{
                    width: '200px',
                    height: '200px',
                   
                  }}
                /> : 
                <Box
                sx={{
                  height: '150px',
                  width: '150px',
                  border: '1px dashed #ccc',
                  padding: '1rem',
                  position: 'relative',
                  textAlign: 'center',
                 
                }}
              > <Avatar
                alt={`${doctor.full_name}`}
               src={doctor.image}
          sx={{
                  width: 100,
                  height: 100,
                  margin: '0 auto',
                }}
              /> </Box> }
             
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6">{doctor.full_name}</Typography>
              <Typography variant="subtitle1">{doctor.speciality}</Typography>
              <Typography variant="body2">
                {doctor.experience} of experience
              </Typography>
              <Typography variant="body2">
                Consultancy Fee : {doctor.charges} $ 
              </Typography>
              <Typography>
                  {doctor.intro}
              </Typography>
            </Grid>
          </Grid>
      
      </Box>
      <hr/>
      <Box>
     <h2 style={{color:'var(--color-text-primary)'}}>Available Slots</h2>
     <Box sx={{backgroundColor:'aqua'}}>
     <TableContainer component={Paper}>
     <Table >
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {doctorSlots.map((slot) => {
      
            return (
              <TableRow key={slot.id}>
                <TableCell>{slot.attributes.start_date}</TableCell>
                <TableCell>{formatTime(slot.attributes.time)}</TableCell>
                <TableCell>{slot.attributes.address}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </TableContainer>
      
    </Box>
 

      </Box>
  
      <Box>
      <h2 style={{color:'var(--color-text-primary)',margin:'10px'}}>Services</h2>
      <Box sx={{}}>
     <TableContainer component={Paper}>
     <Table >
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Service</TableCell>
             <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {services.map((service) => {
      
            return (
              <TableRow key={service.id}>
                <TableCell colSpan={2}>{service.attributes.name}</TableCell>
                <TableCell>{service.attributes.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </TableContainer>
      
    </Box>
      </Box>
      </Container>
      ) : (
        <p>Loading...</p>
      )}
    </Container>
    </EnterTransition>
  );
};

export default VisitDoctorsProfile;
