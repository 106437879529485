import React, { useState } from 'react';
import '../styles/subscription.css'
import bgavatar from '../assets/career.svg';
import wave from '../assets/wave.png';
import { FiUser, FiLock, FiMail, FiPhone, FiSend } from 'react-icons/fi';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EnterTransition from '../utility/EnterTransition.js'



const Careers = () => {

  const MySwal = withReactContent(Swal);


  const [newCandidate, setNewCandidate] = useState({

    name: '',
    email: '',
    contact: '',
    position: ''


  });
  const [errors, setErrors] = useState({
    name: '',
    contact: '',
    email: '',
    position: '',
  });
  const [nameFocus, setNameFocus] = useState(false);
  const [contactFocus, setContactFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [positionFocus, setPositionFocus] = useState(false);

  const handleNameFocus = () => {
    setNameFocus(true);
  };

  const handleNameBlur = (e) => {
    if (!e.target.value.trim()) {
      setNameFocus(false);
    }
  };

  const handleContactFocus = () => {
    setContactFocus(true);
  };

  const handleContactBlur = (e) => {
    if (!e.target.value.trim()) {
      setContactFocus(false);
    }
  };

  const handleEmailFocus = () => {
    setEmailFocus(true);
  };

  const handleEmailBlur = (e) => {
    if (!e.target.value.trim()) {
      setEmailFocus(false);
    }
  };

  const handlePositionFocus = () => {
    setPositionFocus(true);
  };

  const handlePositionBlur = (e) => {
    if (!e.target.value.trim()) {
      setPositionFocus(false);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewCandidate((prevCandidate) => ({
      ...prevCandidate,
      [name]: value,
    }));

  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newCandidate.name.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Name cannot be empty' }));
      return;
    }

    if (!isValidPhoneNumber(newCandidate.contact)) {
      setErrors((prevErrors) => ({ ...prevErrors, contact: 'Invalid phone number' }));
      return;
    }

    if (!isValidEmail(newCandidate.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
      return;
    }
    if (newCandidate.position.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, position: 'Position cannot be empty' }));
      return;
    }

    const data = {
      ...newCandidate,
      subject: "New Career Request",
      key: "careers",
      forAdminOnly: true,
    }
    const apiUrl = process.env.REACT_APP_BASE_URL + '/api/careers';

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/public/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

    } catch (error) {
      // Handle network or other errors
      console.error('Error occurred while making the API request:', error);

    }
    setNewCandidate({
      name: '',
      email: '',
      contact: '',
      position: ''

    })
    setErrors({
      name: '',
      contact: '',
      email: '',
      position: '',
    })
    MySwal.fire({
      icon: 'success',
      title: 'Request Sent',
      text: 'Your request has been sent',
      confirmButtonText: 'OK!',
      // customClass: {
      //   confirmButton: 'cutom-swal-confirm-button',
      //   cancelButton: 'custom-swal-cancel-button',
      // },
    })
  };
  // const isValidPhoneNumber = (value) => /^\d{10}$/.test(value);
  const isValidPhoneNumber = (value) => value && value.length >= 9;
  const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  return (
    <EnterTransition>
      <div>
        <img className="wave" src={wave} alt="Wave" />
        <div className="pcontainer">
          <div className="img">
            <img src={bgavatar} alt="Background" className="img" />
          </div>
          <div className="login-content">
            <form onSubmit={handleSubmit}>
              <h2 className="title">Career Inquiry</h2>
              {/* <h6>We are not hiring at this time but please share your information and we will notify you when positions open</h6> */}
              <div className={`input-div one ${nameFocus ? 'focus' : ''} ${errors.name ? 'error' : ''} ${errors.name ? 'error-border' : ''}`}>
                <div className="i">
                  <FiUser />
                </div>
                <div className="div">
                  <h5>Name</h5>
                  <input
                    type="text"
                    name="name"
                    className={`input ${errors.name ? 'error-border' : ''}`}
                    onFocus={handleNameFocus}
                    onBlur={handleNameBlur}
                    value={newCandidate.name}
                    onChange={handleChange}
                  />
                  {errors.name && <small className="error-text">{errors.name}</small>}

                </div>
              </div>
              <div className={`input-div one ${contactFocus ? 'focus' : ''} ${errors.contact ? 'error' : ''}`}>
                <div className="i">
                  <FiPhone />
                </div>
                <div className="div">
                  <h5>Contact</h5>
                  <input
                    type="text"
                    name="contact"
                    className={`input ${errors.contact ? 'error-border' : ''}`}
                    onFocus={handleContactFocus}
                    onBlur={handleContactBlur}
                    value={newCandidate.contact}
                    onChange={handleChange}
                  />
                  {errors.contact && <small className="error-text">{errors.contact}</small>}

                </div>
              </div>
              <div className={`input-div one ${emailFocus ? 'focus' : ''} ${errors.email ? 'error' : ''}`}>
                <div className="i">
                  <FiMail />
                </div>
                <div className="div">
                  <h5>Email</h5>
                  <input
                    type="text"
                    name="email"
                    className={`input ${errors.email ? 'error-border' : ''}`}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    value={newCandidate.email}
                    onChange={handleChange}
                  />
                  {errors.email && <small className="error-text">{errors.email}</small>}

                </div>
              </div>
              <div className={`input-div one ${positionFocus ? 'focus' : ''}`}>
                <div className="i">
                  <FiUser />
                </div>
                <div className={`input-div one ${positionFocus ? 'focus' : ''}`}>
                  <h5>Position Interested In</h5>
                  <input
                    type="text"
                    name="position"
                    className={`input ${errors.position ? 'error-border' : ''}`}
                    onFocus={handlePositionFocus}
                    onBlur={handlePositionBlur}
                    value={newCandidate.position}
                    onChange={handleChange}
                  />
                  {errors.position && <small className="error-text">{errors.position}</small>}

                </div>
              </div>
              <button type="submit" className="btn">
                <FiSend /> Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </EnterTransition>
  );
};

export default Careers;
