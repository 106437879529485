import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './search.css'
import {Select,InputLabel,FormControl, TextField, Button, Container,Grid, InputAdornment  } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import {MdMedicalServices,MdLocationOn} from 'react-icons/md';
import {FaHandHoldingDollar} from 'react-icons/fa6';
import {BsArrowRightShort} from 'react-icons/bs'
import { render } from '@testing-library/react';



const SearchBar = ({backgroundColor, backgroundButton, marginT, marginB}) => {

  const [searchQuery, setSearchQuery] = useState({
    appointment: '',
    insurance: '',
    zipcode: ''

  })

  const handleChange = (e) =>{
    e.preventDefault()
    const {name, value} = e.target
    setSearchQuery({...searchQuery, [name]: value})
    
  }

  const navigate = useNavigate();
  const handleSubmit = () => {
  
    const queryParams = new URLSearchParams(searchQuery);
    const url = `/doctorsprofile?${queryParams.toString()}`;
    const appointment = searchQuery.appointment;
    const insurance = searchQuery.insurance;
    const zipcode = searchQuery.zipcode;
    if (appointment && insurance && zipcode){
      navigate(url);
    }
   
  }


  return (
  
    <Container className='searchbar_area' sx={{boxShadow:'2px 3px 10px silver', backgroundColor, p:2 , borderRadius: '5px', overflow: 'hidden', marginBottom: `${marginB}`, marginTop: `${marginT}` }}>
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={4} lg={3}>
        <FormControl sx={{ m: 2, minWidth: 270 }}>
          <InputLabel><MdMedicalServices style={{marginRight:'5px'}}/> Type of Therapy</InputLabel>
          <Select
            value={searchQuery.appointment}
            label="Type of Appointment"
            onChange={handleChange}
            name='appointment'
            required
            sx={{textAlign:'left'}}
          >
            <MenuItem value="Individual">Individual</MenuItem>
            <MenuItem value="Couples">Couples</MenuItem>
            <MenuItem value="Family">Family</MenuItem>
            <MenuItem value="Adolescence">Adolescence</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <FormControl sx={{ m: 2, minWidth: 270 }} size="large">
          <InputLabel><FaHandHoldingDollar  style={{marginRight:'5px'}}/> Insurance</InputLabel>
          <Select
            value={searchQuery.insurance}
            label="Insurance"
            onChange={handleChange}
            name='insurance'
            required
            sx={{textAlign:'left'}}
          >
            <MenuItem value="Atena">Atena</MenuItem>
            <MenuItem value="United Health Care">United Health Care</MenuItem>
            <MenuItem value="Blue Cross Blue Shield">Blue Cross Blue Shield</MenuItem>
            <MenuItem value="Cigna">Cigna</MenuItem>
            <MenuItem value="Medicare">Medicare</MenuItem>
            <MenuItem value="I'm Paying Myself">I'm paying myself</MenuItem>
            <MenuItem value="Confirm Insurance Later">Confirm Insurance Later</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <FormControl sx={{ m: 2, minWidth: 270 , textAlign:'center'}} size="large">
          <TextField
            placeholder="Zip Code"
            value={searchQuery.zipcode}
            onChange={handleChange}
            name="zipcode"
            required
           InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdLocationOn />
              </InputAdornment>
            ),
          }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2} lg={3}>
        <Button type="button" onClick={handleSubmit}
         sx={{ backgroundColor:`${backgroundButton}`, m: 2, minWidth: 240, height:'3.5rem', color:'white', textTransform:'capitalize',
         '&:hover': {
          backgroundColor: `${backgroundButton}`, 
        }
        
        }}>
          Explore
        </Button>
      </Grid>
    </Grid>
  </Container>
  
  
  )
}

export default SearchBar