import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header'
import Footer from '../src/components/footer/Footer'
import Home from './pages/Home';
import FAQs from './pages/FAQs';
import Login from './pages/Login';
import ClientLogin from './pages/ClientLogin'
import ProviderLogin from './pages/ProviderLogin'
import AboutUs from './pages/AboutUs';
import Providers from './pages/Providers'
import Services from './pages/Services';
import BookAppointment from './pages/BookAppointment';
import CreateProfile from './pages/CreateProfile';
import ViewProfile from './pages/ViewProfile';
import UpdateClientProfile from './pages/UpdateClientProfile';
import ScheduleAppointments from './pages/ScheduleAppointments';
import PatientHistory from './pages/PatientHistory';
import DoctorsProfile from './pages/DoctorsProfile';
import VisitDoctorsProfile from './pages/VisitDoctorsProfile'
import AppointmentDetailsForm from './pages/AppointmentDetailsForm';
import Subscription from './pages/Subscription';
import Careers from './pages/Careers';
import ViewDoctorProfile from './pages/ViewDoctorProfile';
import UpdateDoctorProfile from './pages/UpdateDoctorProfile';
import AuthMiddleware from '../src/middleware/AuthMiddleware'
import GuestMiddleware from '../src/middleware/GuestMiddleware'
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import NotFound from './pages/NotFound';
import PaymentSuccess from './pages/PaymentSuccess';

function App() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    console.log("hash", hash)
    if(typeof window != 'undefined') {

      if(hash && document.getElementById(hash.replace("#","")) ) {
        document.getElementById(hash.replace("#","")).scrollIntoView()
  
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [pathname, hash])

  return (
    <div className="App">

      <Header />

      <Routes>
        {/* Guest Routes */}
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        {/* <Route path='/faq' element={<FAQs />} /> */}
        
        {/* <Route path='/login' element={<Login />} />
        <Route path='/clientlogin' element={<ClientLogin />} />
        <Route path='/providerlogin' element={<ProviderLogin />} /> */}
        
        <Route path="/requestconsultation/:doctorId" element={<BookAppointment />} />
        <Route path="/doctorsprofile" element={<DoctorsProfile />} />
        <Route path="/visitdoctorsprofile/:doctorId" element={<VisitDoctorsProfile />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="*" element={<NotFound />} />

        {/* Consultant Routes */}
        {/* <Route path='/appointmentdetails' element={<AppointmentDetailsForm/>}/>
     <Route path='/viewdoctorprofile' element={<ViewDoctorProfile/>}/>
     <Route path='/updatedoctorprofile' element={<UpdateDoctorProfile/>}/>
     <Route path="/patient-history/:patientId" element={<PatientHistory />} /> */}


        {/* Client Routes */}
        {/* <Route path='createprofile' element={<CreateProfile/>}/>
     <Route path='/viewprofile' element={<ViewProfile/>}/>
     <Route path='/updateclientprofile' element={<UpdateClientProfile/>}/>
     <Route path='/scheduleappointment' element={<ScheduleAppointments/>}/> */}








      </Routes>

      <Footer />

    </div>
  );
}

export default App;
