import {
  TextField,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const AppointmentDetailsForm = (props) => {
  const { formik } = props;
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={6}
      >
        <TextField
          name="appointmentDate"
          label="Appointment Date"
          variant="outlined"
          size='small'
          fullWidth
          type='date'
          value={formik.values.appointmentDate}
          onChange={formik.handleChange}
          error={formik.touched.appointmentDate && Boolean(formik.errors.appointmentDate)}
          helperText={formik.touched.appointmentDate && formik.errors.appointmentDate}
          InputLabelProps={{ shrink:true,required: false }}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          name="time"
          label="Time"
          variant="outlined"
          size="small"
          fullWidth
          type='time'
          value={formik.values.time}
          onChange={formik.handleChange}
          error={formik.touched.time && Boolean(formik.errors.time)}
          helperText={formik.touched.time && formik.errors.time}
          InputLabelProps={{ shrink:true,required: false }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="outlined" >
          <InputLabel id="meetingType">Meeting Type</InputLabel>
          <Select
            labelId="meetingType"
            id="meetingType"
            name="meetingType"
            label="Meeting Type"
            value={formik.values.meetingType}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.meetingType && formik.errors.meetingType)}
            helperText={formik.touched.meetingType && formik.errors.meetingType}
            sx={{textAlign:'left'}}
            
          >
            <MenuItem value="Virtual">Virtual</MenuItem>
            <MenuItem value="In Person">In Person</MenuItem>
           
          </Select>
          {formik.touched.meetingType && formik.errors.meetingType ? (
         <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
         >
            {formik.touched.meetingType && formik.errors.meetingType}
         </FormHelperText>
         ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="paymentMethod">Payment Method</InputLabel>
          <Select
            labelId="paymentMethod"
            id="paymentMethod"
            name="paymentMethod"
            label="Payment Method"
            value={formik.values.paymentMethod}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.paymentMethod && formik.errors.paymentMethod)}
            helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
            sx={{textAlign:'left'}}
            
           
          >
            <MenuItem value="Insurance">Insurance</MenuItem>
            <MenuItem value="Paying Myself">Paying Myself</MenuItem>
           
          </Select>
          {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
         <FormHelperText
            sx={{ color: "#bf3333", marginLeft: "16px !important" }}
         >
            {formik.touched.paymentMethod && formik.errors.paymentMethod}
         </FormHelperText>
         ) : null}
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default AppointmentDetailsForm;