import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import '../../styles/loader.css';

const Loader = () => {
  return (
    <div className="loader-overlay">
        <div className="blocks-wrapper">
          <RotatingLines
            height="80"
            width="80"
            strokeColor="white"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </div>
      </div>
  )
}

export default Loader