// EnterTransition.js
import { motion } from 'framer-motion';

const transitionVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
};

const transitionSettings = {
  type: 'tween', 
  duration: 1, 
  ease: 'easeInOut', 
};

const EnterTransition = ({ children }) => (
  <motion.div
  variants={transitionVariants}
  initial="hidden"
  animate="visible"
  exit="exit"
  transition={transitionSettings}
  >
    {children}
  </motion.div>
);

export default EnterTransition;
