// src/components/NotFound.js
import React from 'react';
import './NotFound.css'; // Import a CSS file for styling
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
    return (
        <div className="not-found">
            <h1 className="not-found-title">Thank You!</h1>
            <p className="not-found-message">Payment Successfull</p>
            <div className='text-center'>
                <Link to="/" className='btn btn-primary pt-2'>Go Home</Link>
            </div>
        </div>
    );
}

export default PaymentSuccess;
