import React, { useState } from 'react'
import { Container, Box, TextField, Button, Grid, Paper, Typography, FormControlLabel, ToggleButton, ToggleButtonGroup, Switch } from '@mui/material';
import Banner from '../components/Banner/Banner.js';
import bg from '../assets/inner-bg.webp'
import { FiCheck } from "react-icons/fi";
import EnterTransition from '../utility/EnterTransition.js'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MembershipDetails = ({ billingType }) => {
  const membershipPoints = [
    'Insurance Management Tool',
    'Caseload Management',
    'Online Billing Organizer',
    'Data and Reporting',
    'Video Conferencing',
    'Notes and Profile Saving for Clients',
  ];

  return (
    <Box sx={{ textAlign: 'center' }}>

      <Container className='membership_box' sx={{
        height: '50vh', width: '400px', padding: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', borderRadius: '10px', boxShadow: '1px 1px 10px silver',
        '@media (min-width: 768px)': {
          width: '400px',

        },


        '@media (max-width: 767px)': {

          width: '300px',
          marginBottom: '1rem'
        },
      }}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          Membership
        </Typography>
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
          {membershipPoints.map((point, index) => (
            <li key={index} style={{ margin: '10px' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <span role="img" aria-label="tick mark" style={{ marginRight: '10px' }}>
                  <FiCheck style={{ color: 'var(--color-primary)' }} />
                </span>
                {point}
              </Typography>
            </li>
          ))}
        </ul>
      </Container>
    </Box>
  );
};


const Providers = () => {
  const MySwal = withReactContent(Swal);

  const features = [
    'Insurance Management',
    'Caseload Management',
    'Online Bill Payments',
    'Data and Reporting',
    'Telehealth Zoom',
  ];
  const [billingType, setBillingType] = useState('monthly');
  const [checked, setChecked] = useState(false);


  const handleBillingTypeChange = (event, newBillingType) => {
    if (newBillingType !== null) {
      setBillingType(newBillingType);
    }
  };

  const handleChecked = () => {
    setChecked((prev) => !prev);
  };

  const [email, setEmail] = useState('')

  const onSubmit = async () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!email || !reg.test(email)) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Invalid email.',
      });
      return;
    }
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/public/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          key: 'providerDemoRequest',
          subject: 'Bluu Health Provider Demo',
        }),
      });
      const data = await response.json();
      if (!data.success) {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message,
        });
        return;
      }
      setEmail('');
      MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Someone from our team will be in touch shortly.',
      });
    } catch (e) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      });
      console.log("error", e);
    }

  }

  return (
    <>
      <EnterTransition>
        <Banner
          background={bg}
          title={"For Providers"}
        />
        <Container sx={{ padding: '5%' }}>
          <h2>Bluu Health is a management software tailored to optimize your practice management from start to finish</h2>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '3%',
              flexDirection: 'row',




              '@media (max-width: 767px)': {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column', // Apply flex-wrap for sm devices
                // Other styles for sm devices if needed
              },
            }}
          >
            <TextField
              label="Email Address"
              variant="outlined"
              size="medium"
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              sx={{
                width: '400px',

                '@media (min-width: 768px)': {
                  width: '400px',

                },


                '@media (max-width: 767px)': {

                  width: '300px',
                  marginBottom: '1rem'
                },

              }}
            />
            <Button onClick={onSubmit} sx={{ height: '3.5rem', background: 'red', color: 'white', textTransform: 'capitalize', marginLeft: '5px' }}>
              Free Demo
            </Button>
          </Box>

          <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3%', marginBottom: '3%' }}>
            {features.map((feature, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={index === 4 ? 12 : 6}
                md={4}
                lg={2}
                sx={{
                  order: index === 4 ? 2 : 'unset', // Set order for the 5th element
                  display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
              >

                <Paper
                  elevation={3}
                  sx={{
                    height: '5rem',
                    width: '10rem',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    background: 'var(--color-primary)',
                    color: 'white',
                    margin: '10px ',
                    fontSize: 'small',
                  }}
                >
                  <Typography>{feature}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box>
            <h1 style={{ color: 'var(--color-primary)' }}>SIGN UP TODAY</h1>
            <Box sx={{ margin: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>
                <Typography>Billed Monthly</Typography>
              </div>

              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChecked} />}
                labelPlacement="start"
                sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: 'var(--color-primary)' } }}
              />

              <div style={{ marginLeft: '20px' }}>
                <Typography>Billed Annually (Savings)</Typography>
              </div>
            </Box>
            <MembershipDetails billingType={billingType} />
          </Box>

        </Container>
      </EnterTransition>
    </>
  )
}



export default Providers