import React from 'react'
import { Button, Row, Col, Card } from 'antd';
import './PricingCard.css'

const PricingCard = () => {
    return (
        <>
            <div id='pricing' className="pricing-section">
                <div className='pricing-first-section'>
                    <h1 className="pricing-heading">Simplify your practice today</h1>
                    <p style={{ color: "#2E3E5C", font: "Poppins", marginBottom: "34px" }}>explore all our features with a 30-day trial. Once it ends,<br /> choose a plan that’s right for you.</p>
                    <div className='pricing-tabs' style={{ flex: 1 }}>
                        <div style={{
                            display: "flex",
                            flex: 1,
                            background: "#2E3E5C",
                            height: "100%",
                            color: "#fff",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px"
                        }}>
                            For Month
                        </div>
                        <div className='pricing-year-tab' style={{ color: "#2E3E5C", flex: 1 }}>
                            For Year <span style={{
                                background: "#F9D783",
                                padding: "5px",
                                borderRadius: "5px",
                                whiteSpace: "nowrap"
                            }}>Save 20%</span>
                        </div>
                    </div>
                </div>
                <Row gutter={[16, 25]} justify="center">
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 1 }}>
                        <Card title="Monthly" bordered={false} className="pricing-card no-border-title basic-card">
                            <div style={{ borderBottom: "1px solid #001C80", borderTop: "1px solid #001C80" }}>
                                <p style={{ marginTop: "20px", marginBottom: 0, color: "#000000", fontWeight: 600 }}>Starting at, per Month</p>
                                <div className="price">$35</div>
                            </div>
                            <Button onClick={() => window.location = `${process.env.REACT_APP_PORTAL_URL}/auth/subscribe?planType=monthly`} style={{ backgroundColor: '#1E1F4B', padding: '18.46px 64.62px 18.46px 64.62px', borderRadius: "3.69px", width: "100%", fontWeight: 700 }} type="primary">Start for free</Button>
                            <p style={{ textAlign: "center", marginTop: '5px' }}>30 day trial for free</p>
                        </Card>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} >
                        <Card style={{ position: "relative" }} title="Annually" bordered={false} className="pricing-card no-border-title title-color annual-card">
                            <div className='pricing-card-popular'>Most popular</div>
                            <div style={{ borderBottom: "1px solid #FFFFFF", borderTop: "1px solid #FFFFFF" }}>
                                <p style={{ marginTop: "20px", marginBottom: 0 }}>Starting at, per Year</p>
                                <div className="price">$336</div>
                            </div>
                            <Button onClick={() => window.location = `${process.env.REACT_APP_PORTAL_URL}/auth/subscribe?planType=yearly`} style={{ backgroundColor: '#fff', color: "#1E1F4B", padding: '18.46px 64.62px 18.46px 64.62px', borderRadius: "3.69px", width: "100%", fontWeight: 700 }} type="primary">Start for free</Button>
                            <p style={{ textAlign: "center", marginTop: '5px' }}>30 day trial for free</p>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PricingCard