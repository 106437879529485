import {
  TextField,
  Grid
} from '@mui/material';

const PersonalInfoForm = (props) => {
  const { formik } = props;
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <TextField
          name="fullName"
          label="Name"
          variant="outlined"
          size='small'
          fullWidth
          value={formik.values.fullName}
          onChange={formik.handleChange}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
          
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <TextField
          name="email"
          label="Email"
          variant="outlined"
          type='email'
          size="small"
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <TextField
          name="phone"
          label="Contact"
          variant="outlined"
          type="phone"
          fullWidth
          size="small"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          
        />
      </Grid>
      
    </Grid>
  )
}

export default PersonalInfoForm;